<template>
  <div>
    <v-row v-if="isVisible" dense class="mx-1 mt-2">
      <v-col cols="9">
        <gli-text-field
          :label="$t('POI name')"
          v-model="poi.name"
          @change="changeName()"
        ></gli-text-field>
      </v-col>
      <v-col cols="2">
        <div class="caption v-label v-label--active theme--light">{{ $t('Icon') }}</div>
        <v-tooltip bottom :open-delay="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small class="mt-1" @click="openIconDialog()">
              <sprite-icon :icon="poi.icon"></sprite-icon>
            </v-btn>
          </template>
          <span>{{ $t('Modify POI icon') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <poi-dialog></poi-dialog>
  </div>
</template>

<script>
import constants from '~/shared/constants';
import SpriteIcon from './SpriteIcon';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import PoiDialog from '@/components/PoiDialog';

export default {
  name: 'PoiPanel',
  props: ['map', 'draw'],

  data() {
    return {
      makiIcons: constants.MAKI_ICONS,
      poi: {
        name: undefined,
        icon: undefined,
        id: undefined
      },
      isVisible: false
    };
  },

  mounted() {
    this.map.on('draw.create', (e) => {
      const poi = e.features.find(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.POI
      );
      if (!poi) {
        return;
      }

      poi.properties.name =
        this.poi.name && this.poi.name.length
          ? this.poi.name
          : constants.DEFAULT_POI_PROPERTIES.DEFAULT_NAME;
      poi.properties.icon = this.poi.icon;
      this.addPoi(poi);
      this.savePoi();
    });

    this.map.on('draw.selectionchange', (e) => {
      const selectedPoi =
        e.features &&
        e.features.length === 1 &&
        e.features[0].properties._type === constants.FEATURE_TYPES.POI
          ? e.features[0]
          : undefined;

      if (selectedPoi) {
        this.poi.name = selectedPoi.properties.name;
        this.poi.icon = selectedPoi.properties.icon;
        this.poi.id = selectedPoi.id;
        this.isVisible = true;
      } else {
        // if not clicked on a POI
        this.poi.name = undefined;
        this.poi.icon = undefined;
        this.poi.id = undefined;
        this.isVisible = false;
      }
    });
  },

  computed: {
    ...mapGetters('poi', ['editedPoi']),
    ...mapGetters('level', ['editedLevel']),
    ...mapGetters('status', ['currentDrawMode']),
    ...mapFields('poi', 'editedPoi.feature.properties.name', 'editedPoi.feature.properties.icon')
  },

  methods: {
    ...mapActions('poi', { addPoi: 'addNew', editPoi: 'edit', savePoi: 'save' }),
    ...mapMutations('feature', { updateCurrentFeatures: 'updateCurrentFeatures' }),
    ...mapMutations('poi', ['openPoiDialog']),

    openIconDialog() {
      this.openPoiDialog({ icon: this.poi.icon });
    },

    async modifyIcon(icon) {
      this.poi.icon = icon;
      if (this.poi.id) {
        // if an existing POI is modified
        await this.editPoi(this.poi.id);
        this.editedPoi.feature.properties.icon = icon;
        await this.savePoi(this.poi.id);

        this.updateCurrentFeatures(this.editedLevel.id);

        // keep the selection of modified POI
        this.draw.changeMode('simple_select', { featureIds: [this.poi.id] });
      }
    },

    async changeName(keepSelection = true) {
      if (!this.poi.name) {
        this.poi.name = constants.DEFAULT_POI_PROPERTIES.DEFAULT_NAME;
      }

      if (this.poi.id) {
        // if an existing POI is modified
        await this.editPoi(this.poi.id);

        this.editedPoi.feature.properties.name = this.poi.name;

        await this.savePoi(this.poi.id);

        this.updateCurrentFeatures(this.editedLevel.id);

        if (keepSelection) {
          // keep the selection of modified POI
          this.draw.changeMode('simple_select', { featureIds: [this.poi.id] });
        }
      }
    }
  },

  watch: {
    async currentDrawMode() {
      if (this.currentDrawMode === constants.DRAW_MODES.POI) {
        if (this.poi.name !== undefined) {
          await this.changeName(false);
        }
        this.isVisible = true;
        this.poi.id = undefined;
        this.poi.name = constants.DEFAULT_POI_PROPERTIES.DEFAULT_NAME;
        this.poi.icon = constants.DEFAULT_POI_PROPERTIES.DEFAULT_ICON;
      } else {
        this.isVisible = false;
      }
    }
  },

  components: { SpriteIcon, PoiDialog }
};
</script>
