<template>
  <v-dialog persistent :value="isIconEdited" max-width="700px" scrollable>
    <v-card>
      <v-card-title class="primary white--text">{{ $t('Modify POI icon') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pl-5 pr-3 py-3" style="max-height: 500px;">
        <v-btn
          :color="selectedIcon === icon ? 'primary' : ''"
          x-small
          class="ma-1 py-4"
          v-for="icon of makiIcons"
          :key="icon"
          @click="changeIcon(icon)"
        >
          <sprite-icon :icon="icon"></sprite-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn class="primary" @click="modify">
          {{ $t('Modify') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

<script>
import SpriteIcon from './SpriteIcon';
import { mapGetters, mapMutations } from 'vuex';
import constants from '~/shared/constants';

export default {
  name: 'PoiDialog',

  data() {
    return {
      makiIcons: constants.MAKI_ICONS,
      selectedIcon: undefined
    };
  },

  computed: {
    ...mapGetters('poi', ['isNew', 'editedPoi', 'poiIcon', 'isIconEdited'])
  },

  methods: {
    ...mapMutations('poi', ['closePoiDialog']),

    changeIcon(icon) {
      this.selectedIcon = icon;
    },

    cancel() {
      this.closePoiDialog();
    },

    modify() {
      this.$parent.modifyIcon(this.selectedIcon);
      this.closePoiDialog();
    }
  },

  watch: {
    poiIcon() {
      this.selectedIcon = this.poiIcon;
    }
  },

  components: { SpriteIcon }
};
</script>
